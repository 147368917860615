import React, { useEffect } from "react";

import Img1 from "./1.jpeg";
import Img2 from "./2.jpeg";

const HomePage = (): React.ReactElement => {
    useEffect(() => {
        document.body.style.backgroundColor = "#F3F3F3";
    });

    return (
        <div
            style={{
                width: "100%",
                backgroundColor: "#F3F3F3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}
        >
            <img src={Img1} width="800px" />
            <img src={Img2} width="800px" />
        </div>
    );
};

export default HomePage;
